import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { Row, Col, Button as BootstrapButton } from 'react-bootstrap'
import { Link } from 'gatsby'

import { GiovaniliQuery } from '../../types/graphql'

import { Container } from '../../components/Container'
import { NewsFeedGiovanili } from '../../containers/NewsGiovanili'
import { Section } from '../../components/Section'
import { SEO } from '../../components/Seo'
import { Youth } from '../../containers/Youth'

import * as classes from './giovanili.module.scss'

const getTeamCategoryFromSlug = (slug: string) => {
  return slug?.match(/^under-\d{2}-\d{4}-\d{4}$/)![0]
}

type YouthTeamsProps = {
  data: GiovaniliQuery
}

export default function YouthTeams({ data }: YouthTeamsProps) {
  return (
    <Youth cover={data.cover?.childImageSharp?.fluid as FluidObject}>
      <SEO title={'Squadre giovanili'} />

      <Container>
        <Section>
          <Row>
            <Col xs={12} md={5} className={classes.categories}>
              {data.categories.nodes
                .filter((node, index) => {
                  const foundElementIndex = data.categories.nodes.findIndex(
                    (category) => {
                      return (
                        getTeamCategoryFromSlug(node.slug!) ===
                          getTeamCategoryFromSlug(category.slug!) &&
                        node.slug! < category.slug!
                      )
                    }
                  )

                  return index >= foundElementIndex
                })
                .map((node) => {
                  const team = node.slug?.match(/^under-\d{2}-\d{4}-\d{4}$/)![0]

                  return (
                    <BootstrapButton
                      as={Link}
                      to={`/giovanili/${team}`}
                      className={classes.categoryButton}
                      key={node.wordpressId!}
                    >
                      <svg>
                        <rect
                          x='0'
                          y='0'
                          fill='none'
                          width='100%'
                          height='100%'
                        />
                      </svg>
                      {node.name}
                    </BootstrapButton>
                  )
                })}
            </Col>

            <Col xs={12} md={{ offset: 1, span: 5 }} className={classes.story}>
              <p>
                Il Settore Giovanile da sempre costituisce il fiore
                all’occhiello della Pallacanestro Reggiana. Ormai considerato da
                tutti gli addetti ai lavori uno dei meglio organizzati nel
                panorama nazionale, si e meritato la fiducia di partners
                importanti quali Sidis, Cassa Di Risparmio Di Reggio Emilia,
                Bipop-Carire, Grissin Bon e Banca Popolare Di Vicenza già da tre
                stagioni. Nel corso degli anni le squadre dal settore giovanile
                hanno conquistato risultati sportivi di tutto prestigio, quali
                la partecipazione alle Finali Nazionali nelle diverse categorie.
              </p>
            </Col>
          </Row>
        </Section>

        <Section title={'Ultime news'}>
          <NewsFeedGiovanili preview />
        </Section>
      </Container>
    </Youth>
  )
}

export const query = graphql`
  query Giovanili {
    cover: file(name: { eq: "cover_giovanili" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    categories: allWordpressWpSquadra(
      filter: { 
        slug: { regex: "/^under-\\d{2}-\\d{4}-\\d{4}$/" } 
        wordpress_parent: { eq: 74 }
      }
      sort: { fields: [slug], order: ASC }
    ) {
      nodes {
        slug
        name
        wordpressId: wordpress_id
        wordpressParent: wordpress_parent
        season: parent_element {
          name
        }
      }
    }
  }
`
